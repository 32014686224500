<template>
  <div>
    <b-card>
      <ValidationObserver ref="participant" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
          <b-row>
            <!-- <b-col md="12">
            <cropper-images
              :label="$t('global.coverImage')"
              nameOfImage="image.jpg"
              @cropper-save="participant.silk_image = $event.file ? $event.file : null"
              :multi="false"
              :imageUrl="participant.silk_imageUrl"
              :ratio="1"
              :disabled="disableStatus"
            />
          </b-col> -->

            <b-col md="6">
              <input-form
                v-model.number="participant.number"
                :name="$t('participants.number')"
                :label="$t('participants.number')"
                type="number"
                validate="required|numeric"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <input-form
                v-model="participant.odd_form"
                :name="$t('participants.odd')"
                :label="$t('participants.odd')"
                validate="required"
                :disabled="disableStatus"
                :placeholder="$t('participants.oddHint')"
              />
            </b-col>

            <b-col md="6">
              <textarea-form
                v-model="participant.translations[0].description"
                :name="$t('global.descriptionAr')"
                :label="$t('global.descriptionAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <textarea-form
                v-model="participant.translations[1].description"
                :name="$t('global.descriptionEn')"
                :label="$t('global.descriptionEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <input-form
                v-model="participant.last_finishing_positions"
                type="number"
                :name="$t('participants.lastFinishingPositions')"
                :label="$t('participants.lastFinishingPositions')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <main-select
                :options="jockeys"
                label="name"
                v-model="participant.jockey_id"
                :reduce="(i) => i.id"
                :text="participant.jockeyName"
                :labelTitle="$t('jockeys.item')"
                validate="required"
                :showAsInfo="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <main-select
                :options="horses"
                label="name"
                v-model="participant.horse_id"
                :reduce="(i) => i.id"
                :text="participant.horseName"
                :labelTitle="$t('horses.item')"
                validate="required"
                :showAsInfo="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <main-select
                :options="races"
                label="name"
                v-model.number="participant.race_id"
                :reduce="(i) => +i.id"
                :text="participant.raceName"
                :labelTitle="$t('races.item')"
                validate="required"
                :showAsInfo="disableStatus"
              />
            </b-col>

            <b-col v-if="currentPage != itemPages.create" md="6">
              <input-form
                v-model="participant.end_position"
                :name="$t('participants.endPosition')"
                :label="$t('participants.endPosition')"
                type="number"
                validate="required|numeric"
                :disabled="disableStatus"
              />
            </b-col>
          </b-row>

          <form-btns
            v-if="currentPage != itemPages.view"
            :label="submitLabel"
            :loading="loading"
          />
        </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <predictions :participantId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import participantServices from "../services/participants";
import jockeysService from "../../../contributors/jockeys/services/jockeys";
import horsesService from "../../../contributors/horses/services/horses";
import racesService from "../../races/services/races";
import Participant from "../models/Participant";
import Predictions from "../../predictions/views/Predictions.vue";
import { objectToFormData } from "../../../../Utils/helper";
import moduleItemMixin from "../../../../Mixins/moduleItemMixin";

export default {
  mixins: [moduleItemMixin],
  components: { Predictions },
  data() {
    return {
      participant: {
        number: null,
        odd_form: null,
        last_finishing_positions: null,
        end_position: 0,
        end_time: null,
        jockey_id: null,
        horse_id: null,
        race_id: null,
        translations: [
          { id: null, description: "", lang: "ar" },
          { id: null, description: "", lang: "en" },
        ],
      },
      jockeys: [],
      horses: [],
      races: [],
    };
  },
  methods: {
    oddFloat(val) {
      const fraction = val;
      const [numerator, denominator] = fraction.split("/");
      const result = parseInt(numerator) / parseInt(denominator);
      return result;
    },
    async create() {
      participantServices
        .create(this.participant)
        .then((response) => {
          this.loading = false;
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "events.participants" });
          core.showSnackbar("success", response.data.message);
        })
        .catch((err) => {
          this.loading = false;
          core.showSnackbar("error", err.response.data.message);
        });
    },
    async update() {
      participantServices
        .update(this.id, this.participant)
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "events.participants" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    core.index();
  },
  created() {
    if (this.id) {
      participantServices.findOne(this.id).then((response) => {
        const participant = response.data.data;
        this.participant = {
          number: +participant.number,
          odd_form: participant.odd_form,
          last_finishing_positions: participant.last_finishing_positions,
          end_position: 0,
          end_time: participant.end_time,
          jockey_id: participant.jockey_id,
          horse_id: participant.horse_id,
          race_id: +participant.race_id,
          translations: participant.translations,
        };

        this.loading = false;
      });
    } else {
      this.loading = false;
    }

    // if (!this.disableStatus) {
    jockeysService.findAll().then((res) => {
      this.jockeys = res.data.data;
    });
    horsesService.findAll().then((res) => {
      this.horses = res.data.data;
    });
    racesService.findAll().then((res) => {
      this.races = res.data.data;
    });
    // }
  },
};
</script>
