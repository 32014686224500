import api from "@/axios";

export default {
  findAll() {
    return api().get("race");
  },
  findOne(id) {
    return api().get(`admin/race/${id}`);
  },
  create(data) {
    return api().post("admin/race/add", data);
  },
  update(id, data) {
    return api().patch(`admin/race/${id}`, data);
  },
  remove(id) {
    return api().delete(`race/${id}`);
  },
  updateRaceParticipants(data) {
    return api().patch("admin/participant/update/results", data);
  },
  getRaceParticipants(id) {
    return api().get(`admin/race/${id}/participants`);
  },
};
