import api from '@/axios'

export default {
  findAll () {
    return api().get('participant')
  },
  findOne (id) {
    return api().get(`admin/participant/${id}`)
  },
  create (data) {
    return api().post('admin/participant/add', data)
  },
  update (id, data) {
    return api().patch(`admin/participant/${id}`, data)
  },
  remove (id) {
    return api().delete(`participant/${id}`)
  }
}
